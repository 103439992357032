"use strict";
let $ = require("jQuery");

let $win = $(window),
    $main = $("#js-companytop"),
    $nav = $("nav"),
    navHeight = $nav.outerHeight(),
    footerHeight = $("footer").outerHeight(),
    docmentHeight = $(document).height(),
    navPos = $nav.offset().top,
    fixedClass = "is-fixed",
    hideClass = "is-hide";

$win.on("load scroll", function() {
  let value = $(this).scrollTop(),
      scrollPos = $win.height() + value;
  //console.log("navPos=" + navPos + ", value=" + value);

  if ( value >= navPos ) {
    if ( docmentHeight - scrollPos <= footerHeight ) {
      $nav.addClass(hideClass);
    } else {
      $nav.removeClass(hideClass);
    }
    $nav.addClass(fixedClass);
    // $main.css("margin-top", "56px");
    $main.css("margin-top", navHeight);
  } else {
    $nav.removeClass(fixedClass);
    $main.css("margin-top", "0");
  }
});

